<script setup>
import axios from 'axios'
import { ref, computed, onBeforeMount } from 'vue'
import { useRoute } from 'vue-router'
import html2pdf from 'html2pdf.js'
import useExamStore from '@/stores/exam'
import useMapaStore from '@/stores/mapa'
import useFindingsDetailsStore from '@/stores/findings'
import useImageStore from '@/stores/images'
import useGeneralStore from '@/stores/general'
import NavigationDrawer from "@/components/navigationDrawer.vue"
import ExamHead from "@/components/exam-head/ExamHead.vue"
import PatientData from "@/components/patient-data/PatientData.vue"
import OdontogramVision from "@/components/odontogram-vision/OdontogramVision.vue"
import TeethType from "@/components/teeth-type/TeethType.vue"
import FoundDetails from '@/components/found-details/FoundDetails.vue'
import snackbarNotification from '@/components/snackbarNotification.vue'

const route = useRoute()
const storeExam = useExamStore()
const storeMapa = useMapaStore()
const storeFindings = useFindingsDetailsStore()
const storeImage = useImageStore()
const storeGeneral = useGeneralStore()

const showDrawer = ref(true)
const isVector = ref(true)
const isImage = ref(false)

const snackbarRef = ref(null)

const orderTeeth = [
  18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28,
  38, 37, 36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48
]

const showVector = () => {
  isVector.value = true
  isImage.value = false
}

const showImage = () => {
  isImage.value = true
  isVector.value = false
}

const dentesAusentesAgrupados = computed(() => {
  const findings = storeFindings.getFindingsDetails
  
  const teethOut = Object.keys(findings)
    .filter(key => findings[key].fields.id_alteracao === 1)
    .map(key => findings[key].fields.num_dente)
  
  storeFindings.setTeethOut(teethOut)
  return teethOut
})

const downloadPdf = () => {
  const el = document.getElementById('laudo-download')
  el.style.display = 'block'
  html2pdf(el, {
    margin: [1, 4, 1, 4],
    filename: 'laudo-exam.pdf'
  }).then(() => {
    el.style.display = 'none'
  })
} 

const concluirLaudo = async () => {
  try {
    const id_exam = route.params.id_exam
    const token = localStorage.getItem("jwtToken")
    await axios.patch(`http://www.api-plataforma.machiron.com.br/exam/${id_exam}`, {
      sg_status: 'R',
    },{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    })
    snackbarRef.value.showSuccess("Laudo revisado com sucesso.")
  } catch(e) {
    console.error('Erro ao atualizar status: ', e)
    snackbarRef.value.showError("Erro ao revisar laudo.")
  }

  window.location.href = '/list'
}

onBeforeMount(async () => {
  const examId = route.params.id_exam
  await Promise.all([
    storeExam.fetchExamDetails(examId),
    storeMapa.fetchMapa(),
    storeFindings.fetchFindings(examId),
    storeImage.fetchImages(examId)
  ])
  storeGeneral.setIsRequestLoad(true)
})

</script>

<template>
  <v-container v-if="storeGeneral.isRequestLoad">
    <snackbarNotification ref="snackbarRef" />
    <v-row >
      <v-col v-if="showDrawer" class="d-none d-lg-flex" cols="2">
        <NavigationDrawer />
      </v-col>

      <v-col :cols="showDrawer ? 10 : 12" id="pdf-content">
        <v-row>
          <!-- exam details head intro -->
          <v-col cols="12" class="d-flex justify-end">
            <ExamHead />
          </v-col>
          <v-col cols="8">
            <PatientData />
          </v-col>
          <v-col cols="4" class="d-flex align-end justify-end">
            <button 
              class="pg-exam-details__button-vision d-flex align-center justify-center mr-2"
              :class="{ active: isVector }"
              @click="showVector"
            >
              <img
                src="@/assets/icons/teeth.svg"
                alt="Icon"
                class="pg-exam-details__icon"
              />
            </button>
            <button 
              class="pg-exam-details__button-vision d-flex align-center justify-center"
              @click="showImage"
              :class="{ active: isImage }"
            >
              <img
                src="@/assets/icons/exam.svg"
                alt="Icon"
                class="pg-exam-details__icon"
              />
            </button>
          </v-col>
          <!-- exam details head end -->

          <!-- odontogram intro -->
          <v-col class="d-flex justify-center" cols="12">
            <OdontogramVision 
              :isVector="isVector"
              :isImage="isImage"
            />
          </v-col>
          <!-- odontogram end -->

          <!-- dentição intro -->
          <v-col cols="12" class="mt-4">
            <TeethType />
          </v-col>
          <!-- dentição end -->

          <!-- found details intro -->
          <v-col cols="12" class="mt-4">
            <FoundDetails />
            
            <button 
              class="pg-exam-details__button-finish"
              @click="concluirLaudo"
            >Finalizar</button>
            <button 
              class="pg-exam-details__button-pdf"
              @click="downloadPdf"
            >Baixar PDF</button>
          </v-col>
          <!-- found details end -->

          <!-- intro laudo pdf -->
           <v-col cols="12" class="laudo">
            <div id="laudo-download" class="laudo-download">
              <div class="laudo__head">
                <PatientData />
                <ExamHead 
                  :is-download="true"
                  class="laudo__head--signature" 
                />
              </div>
              <OdontogramVision 
                :isVector="isVector"
                :isDownload="true"
              />

              <div class="tipo-denticao">
                <h4 class="tipo-denticao__title">Tipo de dentição (com ausentes e extranum.)</h4>
                <ul class="tipo-denticao__list">
                  <li class="tipo-denticao__list--item">
                    <span>{{ storeExam.mapTipoDenticao }} - ({{ dentesAusentesAgrupados.length }}) Elementos ausentes: {{ dentesAusentesAgrupados.join(', ') }}</span>
                  </li>
                  <li class="tipo-denticao__list--item">
                    <span>Nenhum elemento Extranumerário</span>
                  </li>
                </ul>
              </div>

              <div class="detalhe-achados">
                <h4 class="detalhe-achados__title">Detalhamento dos achados</h4>
                                
                <div class="detalhe-achados__list">
                  <!-- lista de achados anatômicos -->
                  <div class="detalhe-achados__list--item">
                    <span
                      v-for="(count, name) in storeFindings.anatomicos" 
                      :key="name"
                    >
                      ({{ count }}) {{ name }}
                    </span>
                  </div>
                  <!-- lista de achados dentais na ordem papaiz -->
                  <div v-if="storeGeneral.isTeethOrder">
                    <div 
                      v-for="(i, index) in orderTeeth"
                      :key="i"
                    >
                      <div
                        v-for="(items, dente) in storeFindings.dentais"
                        :key="dente"
                        :class="{ lastItem: index === storeFindings.dentais.length }"
                      >
                        <div 
                          v-if="i === parseInt(dente)" 
                          class="detalhe-achados__list--item"
                        >
                          Dente {{ i }}:      
                          <span 
                            v-for="(item, index) in items"
                            :key="index"
                          >
                            {{ item.nm_alteracao }}
                            <span 
                              v-if="item.observacao"
                              class="detalhe-achados__list--observation"
                            > | {{ item.observacao }}</span>
                            <span v-if="index < items.length - 1">, </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- lista de achados ordenados por achado -->
                  <div v-if="storeGeneral.isFidingOrder">
                    <div 
                      v-for="achado in storeFindings.groupFindingsOrder"
                      :key="achado"
                      class="detalhe-achados__list--item"
                    >
                      {{ achado.nm_alteracao }}:
                      <span 
                        v-for="(dente, index) in achado.alteracoes"
                        :key="dente.num_dente"
                      >
                        Dente {{ dente.num_dente }}
                        <span 
                          v-if="dente.observacao"
                          class="detalhe-achados__list--observation"
                        > | {{ dente.observacao }}</span>
                        <span v-if="index < achado.alteracoes.length - 1">, </span>
                      </span>
                    </div>
                  </div>
                </div>
                

              </div>
            </div>
           </v-col>
          <!-- end laudo pdf -->
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss" scoped>
  @import '_exam-details.scss'

</style>
